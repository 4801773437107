export const gateway = "https://mitch-api.link.wawanesalife.com";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"mitch.link.wawanesalife.com": {
		a: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: "110 Yonge St. Suite 403,Toronto,ON,M5C 1T4",
			environment_id: "stable",
			map1: "1555 Wentworth St. Unit 200, Whitby,Whitby,ON,L1N 9T6",
			brands: "mitch.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
		b: {
			broker: "mitch",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://mitchinsurance.com/privacy-policy/",
			scope: "mitch#branding#mitch.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@mitchinsurance.com",
			video_link: "null",
			map2: "110 Yonge St. Suite 403,Toronto,ON,M5C 1T4",
			environment_id: "stable",
			map1: "1555 Wentworth St. Unit 200, Whitby,Whitby,ON,L1N 9T6",
			brands: "mitch.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-800-731-2228",
			menuPhone: "button",
			environment: "stable",
			website: "https://mitchinsurance.com",
			tcLink: "https://mitchinsurance.com/terms-conditions/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-2QM64W4BM4",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Say hi to lovable insurance!",
			approve_user: "link_mitch",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "mitch.link.wawanesalife.com",
		},
	},
};

//updated on Fri May 17 2024 12:25:54 GMT-0700 (Pacific Daylight Time)
